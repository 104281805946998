import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";

import {
  Row, Col, Alert, Container, Spinner, Card, InputGroup, InputGroupAddon, Input, InputGroupText, CarouselItem, Carousel, CarouselIndicators, CarouselControl
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//Social Media Imports
// import TwitterLogin from "react-twitter-auth"

// actions
import { loginUser, toggleCurrentModal } from "../../store/actions";

// import images
import * as content from "content";

//Import config
import { withTranslation } from "react-i18next";
import TwoFactorAuth from "components/TwoFactorAuth";
import LanguageDropdown from "components/CommonForBoth/TopbarDropdown/LanguageDropdown";

import img1 from "assets/slider/img1.png";
import img2 from "assets/slider/img2.png";
import img3 from "assets/slider/img3.png";

const Login = props => {

  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const dispatch = useDispatch();
  const {  currentModal, modalData } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData
  }));
  const { error, loading, layoutMode } = useSelector(state => ({
    loading: state.Login.loading,
    error: state.Login.error,
    layoutMode: state.Layout.layoutMode,
  }));

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history));
  };
  const images = [
    {
      index: 0,
      src: img1,
    },
    {
      index: 1,
      src: img2,
    },
    {
      index: 2,
      src: img3,
    }
  ];
  const CarouselPage = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
  
    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    };
  
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    };

    const slides = images.map(item => {
      return (
        <CarouselItem
          tag="div"
          key={item.index}
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
        >
          <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={goToIndex} />
          <img src={item.src} alt="" className="login-img" style={{
            maxHeight: "100vh",
            objectFit: "cover",
            width: "100%",
          }} />
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </CarouselItem>
      );
    });

    return (
      <Col lg={9} md={7} className="d-none d-md-block">
        <Carousel
          activeIndex={activeIndex} 
          next={next}
          previous={previous}
          autoPlay={true}
          interval={2000}
        >{slides}
        </Carousel>
      </Col>
    );
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>{props.t("Login")}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0" style={{
            minHeight: "100vh"
          }}>
            <Col lg={3} md={5} className="" style={{
              margin: "auto 0",
            }}>
              <div style={{
                position: "absolute",
                display: "block",
                top: -20,
                right: 0,
                zIndex: 999,
              }}>
                <LanguageDropdown />
              </div>
              <Card className="d-flex p-sm-5 p-4" style={{
                marginBottom: "0px",
                border: 0,
              }}>
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-1 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={
                          layoutMode === "dark" ? content.mainLogo : content.lightLogo
                        } alt="" height="100" />
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      {/* <div className="text-center">
                        <h5 className="mb-0">{props.t("Sign in to continue")}  </h5>
                      </div> */}
                      <AvForm
                        className="custom-form mt-4 pt-2"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v);
                        }}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label={props.t("Email")}
                            className="form-control"
                            placeholder={props.t("Enter email")}
                            type="email"
                            errorMessage={props.t("Enter valid email")}
                            validate={{
                              required: {
                                value: true,
                              },
                              email:{
                                value:true,
                              }
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <label className="form-label">{props.t("Password")}</label>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="">
                                <Link to="/forgot-password" className="text-muted">{props.t("Forgot password?")}</Link>
                              </div>
                            </div>
                          </div>

                          <div className="mb-3">
                            <Row>
                              <Col xs={11} 
                                style={{
                                  paddingRight: "0px",
                                }}>
                                <AvField
                                  name="password"
                                  type={showPassword ? "text" : "password"}
                                  className="form-control"
                                  placeholder={props.t("Enter Password")}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: props.t("Enter valid password")
                                    }
                                  }}
                                  // For disabling pasting
                                  // onPaste={(e) => {
                                  //   e.preventDefault();
                                  // }}
                                />
                              </Col>
                              <Col xs={1}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderTopRightRadius: "0.25rem",
                                  borderBottomRightRadius: "0.25rem",
                                  boxShadow: " 0 1px 2px 0 rgb(0 0 0 / 0.05)"
                                }}
                              >
                                <button className="btn" type="button" onClick={()=>{ setShowPassword(!showPassword) }}>
                                  <i className="mdi mdi-eye-outline"></i>
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="row mb-4">

                        </div>
                        <div className="mb-3">
                          {loading ? <div className="text-center"><Spinner className="ms-2" color="primary" /></div> : <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">Log In</button>}
                        </div>
                        {error && error ? (
                          <Alert color="danger"> {props.t(error)}</Alert>
                        ) : null}
                      </AvForm>
                      
                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          {props.t("Don't have an account ? ")} 
                          <Link to="/register/forex/live" className="text-primary fw-semibold"> 
                            {props.t("Sign up now")} 
                          </Link> 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
        {modalData && currentModal === "TwoFactorAuth" &&
          <TwoFactorAuth
            isOpen={currentModal === "TwoFactorAuth"}
            email={modalData.email}
            type={modalData.type}
            history={props.history}
            toggleOpen={() => {
              dispatch(toggleCurrentModal(""));
            }}>
          </TwoFactorAuth>}
      </div>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(Login));

Login.propTypes = {
  history: PropTypes.object,
};
