import classNames from "classnames";
import CardWrapper from "components/Common/CardWrapper";
import DetailsTable from "./Details";
import { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button, ButtonGroup, Table
} from "reactstrap";
import { getIbClients } from "store/forex/ib/clients/actions";
import { getIbDeposits, getIbWithdraws } from "store/actions";
import CustomPagination from "components/Common/CustomPagination";
import { 
  Tbody, Th, Thead, Tr 
} from "react-super-responsive-table";
import TableLoader from "components/Common/TableLoader";

function Clients({ type, setSelectedClient, ...props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clients, loading } = useSelector(state=>state.forex.ib.clients);
  const { deposits, withdraws } = useSelector(state => state.forex.ib.transactions);
  const [sizePerPage, setSizePerPage] = useState(10);
  const {
    customActiveTab,
    toggleCustom,
  } = props;
  useEffect(() => {
    if (customActiveTab === "1") loadClients();
    else if (customActiveTab === "2" || customActiveTab === "3") loadTransactions();
  }, [customActiveTab, type, sizePerPage]);

  const loadTransactions = (page = 1) =>{
    if (type){
      if (customActiveTab === "2") dispatch(getIbDeposits({
        type: "DEPOSIT",
        accountType: `${type}`.toUpperCase(),
        page,
        limit: sizePerPage
      }));
      else if (customActiveTab === "3") dispatch(getIbWithdraws({
        type: "WITHDRAW",
        accountType: `${type}`.toUpperCase(),
        page,
        limit: sizePerPage
      }));
    }
  };
  
  const loadClients = () => {
    if (type)
      dispatch(getIbClients({
        type
      }));
  };

  const getSelectedColumn = (id) => {
    switch (id) {
      case "1":
        return [  
          {
            dataField: "level",
            text: t("Level"),
            formatter: (val) => <>{val.level}</>,
          },
          {
            dataField: "firstName",
            text: t("First Name"),
            formatter : (val)=> <>{val.firstName}</>
          },
          {
            dataField: "lastName",
            text: t("Last Name"),
            formatter : (val)=> <>{val.lastName}</>
          },
          {
            dataField: "email",
            text: t("Email"),
            formatter : (val)=> <>{val.email}</>
          },
          {
            dataField: "fx",
            text: t("Type"),
            formatter: (val) => <>{(val.fx.isClient === true && val.fx.isIb === true) === true ? "Client - IB" : "" ||  !(val.fx.isClient) &&  val.fx.isIb ?   "IB" : ""   ||  val.fx.isClient && !(val.fx.isIb ) ? "Client" : ""}</>,
          },
          {
            dataField:"",
            formatter:()=> <Button className="color-bg-btn border-0">{t("View")}</Button>
            
          }
        ];
      case "2":
      case "3": 
        return [
          {
            dataField: "name",
            text: t("Name"),
            formatter: (val)=> `${val.customerId.firstName} ${val.customerId.lastName}`
          },
          {
            dataField: "account",
            text: t("Account"),
            formatter: (val) => <>{val.tradingAccountId.login}</>
          },
          {
            dataField: "type",
            text: t("Type"),
            formatter: (val) => <>{val.type}</>
          },
          {
            dataField: "createdAt",
            text: t("Date Created"),
            formatter: (val) => {
              let d = new Date(val.createdAt);
              d = `${d.toLocaleDateString()}, ${d.toLocaleTimeString()}`;
              return d;
            }
          },
          {
            dataField: "transactionId",
            text: t("Transaction Id"),
            formatter: (val) => <>{val._id}</>
          },
          {
            dataField: "gateway",
            text: t("Mode"),
            formatter: (val) => <>{val.gateway}</>
          },
          {
            dataField: "amount",
            text: t("Amount"),
            formatter: (val) => <>{val.amount}</>
          },
          {
            dataField: "status",
            text: t("Status"),
            formatter: (val) => <>{val.status}</>
          },
        ];
      default : 
        return [];
    }
  };

  const getSelectedRow = (id) =>{
    switch (id) {
      case "1":
        return clients;
      case "2":
        return deposits || [];
      case "3":
        return withdraws || [];
      default:
        return [];
    }
  };

  const getLoadingFor = (id) =>{
    switch (id) {
      case "1":
        return loading;
      case "2":
        return deposits.loading;
      case "3":
        return withdraws.loading;
      default:
        return false;
    }
  };
  const getRowClickHandler = (id) =>{
    switch (id) {
      case "1":
        return setSelectedClient;
      default :
        return ()=>{};
    }
  };
  
  const selectedColumn = getSelectedColumn(customActiveTab);
  const selectedRow = getSelectedRow(customActiveTab);
  const isLoading = getLoadingFor(customActiveTab);
  const rowHandler = getRowClickHandler(customActiveTab);

  return ( 
    <>
      <CardWrapper className="nav-tab-custom glass-card shadow">
        <div className="d-flex justify-content-between">
          <h5 className="color-primary">{t("Clients")}</h5>
          {type === "live" &&
          <ButtonGroup>
            <Button
              className={classNames("btn btn-light shadow-lg border-0", {
                "text-white color-bg-btn": customActiveTab === "1",
              })}
              onClick={() => {
                toggleCustom("1");
              }}>
              {t("All Clients")}
            </Button>
            <Button
              className={classNames("btn btn-light shadow-lg mx-1 border-0", {
                "text-white color-bg-btn": customActiveTab === "2",
              })}
              onClick={() => {
                toggleCustom("2");
              }}>
              {t("Deposit")}
            </Button>
            <Button
              className={classNames("btn btn-light border-0 shadow-lg mx-1", {
                "text-white color-bg-btn": customActiveTab === "3",
              })}
              onClick={() => {
                toggleCustom("3");
              }}>
              {t("Withdrawal")}
            </Button>
          </ButtonGroup>}
        </div>
        {/* filter */}
        {/* <div className="pt-3">
          <Filter />
        </div> */}
        {customActiveTab === "1" && (
          <div className="border rounded-3 mt-4">
            <DetailsTable isLoading={isLoading} columns={selectedColumn} rows={selectedRow} onRowClick={rowHandler} />
          </div>
        )}
        {(customActiveTab === "2" || customActiveTab === "3" ) && (
          <>
            <Table borderless responsive className="text-center mb-0">
              <Thead className="text-center table-light">
                <Tr>
                  {selectedColumn.map((column, index) => (
                    <Th data-priority={index} key={index} className="color-primary">
                      {column.text}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody className="text-center">
                {isLoading && <TableLoader colSpan={12} />}
                {!isLoading &&
                  (selectedRow.docs.length > 0 ? (
                    selectedRow.docs.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {selectedColumn.map((column, index) => (
                          <td key={`${rowIndex}-${index}`}>
                            {column.formatter
                              ? column.formatter(row, rowIndex)
                              : row[column.dataField]}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={selectedColumn.length}>
                        <p className="text-center m-3">{t("No Transactions Available")}</p>
                      </td>
                    </tr>
                  ))}
              </Tbody>
            </Table>
            <div className="mt-4">
              <CustomPagination
                {...selectedRow}
                setSizePerPage={setSizePerPage}
                sizePerPage={sizePerPage}
                onChange={loadTransactions}
              />
            </div>
          </>
        )}
      </CardWrapper>
    </> 
  );
}

export default withTranslation()(Clients);