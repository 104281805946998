import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation, useTranslation } from "react-i18next";
import PageHeader from "components/Forex/Common/PageHeader";
import IbPortalDashboard from "./IbPortal";
import { useHistory } from "react-router-dom";
import ClientPortalDashboard from "./clientPortal/ClientPortalDashboard";
import { CUSTOMER_SUB_PORTALS } from "common/constants";
import Loader from "components/Common/Loader";
import { switchSubPortal } from "store/actions";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { subPortal } = useSelector((state) => ({
    subPortal: state.Layout.subPortal
  }));

  const { clientData } = useSelector((state) => (state.Profile));

  useEffect(() => {
    if (!clientData?.fx?.isIb && subPortal === CUSTOMER_SUB_PORTALS.IB) {
      localStorage.setItem("subPortal", CUSTOMER_SUB_PORTALS.LIVE);
      dispatch(switchSubPortal(CUSTOMER_SUB_PORTALS.LIVE));
      history.push("/request-partnership"); 
    }
  }, []);

  return (
    <>
      <MetaTags>
        <title>{t("Dashboard")}</title>
      </MetaTags>
      <div className="dashboard forex-dashboard page-content">
        <Container className="mt-5">
          <PageHeader title="Dashboard"></PageHeader>
          {/* waiting for profile fetching */}
          {!clientData?.fx?.isIb && !(clientData?.fx?.isClient || clientData?.fx?.isDemo) && <Loader />}
          {
            (subPortal === CUSTOMER_SUB_PORTALS.IB && clientData?.fx?.isIb && <IbPortalDashboard />) ||
            (subPortal === CUSTOMER_SUB_PORTALS.LIVE && (clientData?.fx?.isClient || clientData?.fx?.isDemo) && <ClientPortalDashboard />)
          }
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(Dashboard);